import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import Cover from 'components/common/cover'
import UseCase from 'components/common/useCase'
import TabCover from 'components/common/tabCover'
import ContentCarousel from 'components/common/contentCarousel'
import updateGame from '/static/images/use-case/by-scenario/updates_and_patches_for_games_hero.webp'
import { updateGamesTabs, updateGamesFeatures } from '/static/data/use-case/update-games.static'

const UpdatesPatchesGames = () => {
  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: `Use Case: Updates and Patches for Games - Mlytics`,
            metaDescription: `Mlytics' global networks is here to ensure your gaming products to have best quality and performance of delivery.`,
            metaUrl: 'www.mlytics.com/use-case/updates-and-patches-for-games',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section
          style={{
            height: 'calc(100vh - 100px)',
            minHeight: '600px'
          }}
          className="px-9 md:px-[86px] mb-5 lg:max-h-[800px] base:absolute base:left-1/2 base:-translate-x-1/2 base:w-screen base:max-w-[1920px]"
        >
          <Cover
            smallTitle="Extensive Backbone of Your Games"
            title="Delivered Faster. Played Longer."
            imgSrc={updateGame}
            imgAlt="Update Games Hero Picture"
            paragraphContent="As a gaming company, you need the best global networks to ensure the global delivery of your games with high reliability and performance and Mlytics is here to help."
            actionLinkOne="chatbot"
            actionTextOne="Learn more"
          />
        </section>
        <div className="hidden mb-5 lg:max-h-[800px] base:block base:h-[calc(100vh-100px)] base:min-h-[600px]"></div>
        <section className="py-[60px] px-9 md:px-[86px]">
          <div className="w-full flex flex-col gap-10">
            <h2 className="h2-block-title text-black-100 text-center">Challenge</h2>
            <ContentCarousel listItem={updateGamesFeatures} />
          </div>
        </section>
        <section className="py-[60px] px-9 md:px-[86px] flex justify-center">
          <TabCover title="Mlytics Way" tabItems={updateGamesTabs} />
        </section>
        <section className="py-[60px]">
          <UseCase />
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default UpdatesPatchesGames

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

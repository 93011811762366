import carouselOne from '/static/images/use-case/by-scenario/updates_and_patches_for_games_feature_1.webp'
import carouselTwo from '/static/images/use-case/by-scenario/updates_and_patches_for_games_feature_2.webp'
import carouselThree from '/static/images/use-case/by-scenario/updates_and_patches_for_games_feature_3.webp'
import carouselFour from '/static/images/use-case/by-scenario/updates_and_patches_for_games_feature_4.webp'
import tabOne from '/static/images/use-case/by-scenario/updates_and_patches_for_games_scenario_extensive_networks_and_pops.webp'
import tabTwo from '/static/images/use-case/by-scenario/updates_and_patches_for_games_scenario_advanced_caching_with_lowest_miss_rate.webp'
import tabThree from '/static/images/use-case/by-scenario/updates_and_patches_for_games_scenario_china_access.webp'

export const updateGamesTabs = [
  {
    id: 'pops',
    content: 'Extensive networks and PoPs',
    picture: tabOne,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `Mlytics Platform reduces latency by delivering data through over 300 globally dispersed Points of Presence (PoPs) with automated network mapping and intelligent routing.`
  },
  {
    id: 'advancedCaching',
    content: 'Advanced caching with lowest miss rate',
    picture: tabTwo,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `Mlytics platform provides state-of-the-art caching technologies on edge servers to significantly reduce download times in global scale.`
  },
  {
    id: 'chinaAccess',
    content: 'China access',
    picture: tabThree,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `Mlytics' cutting-edge network in China enables businesses to achieve robust penetration and provide dependable access to their users, enhancing overall user experience.`
  }
]

export const updateGamesFeatures = [
  {
    title: 'Traffic Spikes',
    content: 'Major updates attract a large number of players simultaneously, overwhelming servers.',
    imageSrc: carouselOne,
    imageAlt: 'Traffic Spikes'
  },
  {
    title: 'Regional Restrictions',
    content:
      'In regions like China, a reliable access at scale network is absolutely necessary yet very difficult to find.',
    imageSrc: carouselTwo,
    imageAlt: 'Regional Restrictions'
  },
  {
    title: 'Market Competitiveness',
    content: 'Gaming companies need to offer competitive updates and patches to attract and retain players.',
    imageSrc: carouselThree,
    imageAlt: 'Market Competitiveness'
  },
  {
    title: 'Higher Bandwidth Costs',
    content: `Without external networks, gaming companies may incur higher bandwidth costs as all traffic must be handled by the origin server.`,
    imageSrc: carouselFour,
    imageAlt: 'Higher Bandwidth Costs'
  }
]
